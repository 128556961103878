import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { extend } from "lodash";
import ColorPalette from "@acl-services/sriracha-color-palette";
import logger from "@viz-ui/services/logger/logger";
// importing charts
import LineChart from "@viz-ui/components/charts/lineChart/lineChart";
import BarChart from "@viz-ui/components/charts/barChart/barChart";
import MapChart from "@viz-ui/components/charts/mapChart/mapChart";
import PieChart from "@viz-ui/components/charts/pieChart/pieChart";
import BubbleChart from "@viz-ui/components/charts/bubbleChart/bubbleChart";
import AreaChart from "@viz-ui/components/charts/areaChart/areaChart";
import CombinationChart from "@viz-ui/components/charts/combinationChart/combinationChart";
import TreemapChart from "@viz-ui/components/charts/treemapChart/treemapChart";
import HeatMap from "@viz-ui/components/charts/heatMap/heatMap";
import SummaryTableChart from "@viz-ui/components/charts/summaryTableChart/summaryTableChart";

// Importing chart services
import LineChartService from "@viz-ui/services/charts/lineChart/lineChartService";
import BarChartService from "@viz-ui/services/charts/barChart/barChartService";
import MapChartService from "@viz-ui/services/charts/mapChart/mapChartService";
import PieChartService from "@viz-ui/services/charts/pieChart/pieChartService";
import BubbleChartService from "@viz-ui/services/charts/bubbleChart/bubbleChartService";
import AreaChartService from "@viz-ui/services/charts/stackedAreaChart/stackedAreaChartService";
import CombinationChartService from "@viz-ui/services/charts/combinationChart/combinationChartService";
import TreemapChartService from "@viz-ui/services/charts/treemapChart/treemapChartService";
import HeatMapService from "@viz-ui/services/charts/heatMap/heatMapService";
import StatisticsService from "@viz-ui/services/charts/statisticsChart/statisticsVizService";
import SummaryTableService from "@viz-ui/services/charts/summaryTableChart/summaryTableChartService";
import ChartService from "@viz-ui/services/charts/chartService";

//eventService
import ChartDisplayConfigAdapterService from "@viz-ui/services/charts/chartConfigPanel/chartDisplayConfigAdapterService";
import EventService from "@viz-ui/services/eventService/eventService";
import { Localize, AppConfig } from "../../../../storyboards/services/glue/appGlue";
import BoardNotification from "../common/boardNotification/boardNotification";

let visualizationBoard;

const visualizationTemplate = (chartType, dataModel) => {
  let chartViz;
  if (chartType === "SummaryTable") {
    chartViz = { config: visualizationBoard.visualizationConfig, id: visualizationBoard.tableId };
  }
  if (visualizationBoard.showBoard && visualizationBoard.vizLoaded && !visualizationBoard.boardError)
    switch (chartType) {
      case "BarChart":
        return (
          <BarChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "BubbleChart":
        return (
          <BubbleChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "LineChart":
        return (
          <LineChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "PieChart":
        return (
          <PieChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "StackedAreaChart":
        return (
          <AreaChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "Heatmap":
        return (
          <HeatMap
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "SummaryTable":
        return (
          <SummaryTableChart
            tableId={visualizationBoard.tableId}
            boardId={visualizationBoard.boardId}
            columns={visualizationBoard.interpretation.tableConfig.columns}
            formattingOptions={visualizationBoard.interpretation.tableConfig.formatting}
            rawData={visualizationBoard.data}
            data={visualizationBoard.data}
            presentationMode={visualizationBoard.presentationMode}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
            chartViz={chartViz}
            appConfig={AppConfig}
            eventService={EventService}
            dataModel={dataModel}
          />
        );
      case "Treemap":
        return (
          <TreemapChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            labelFontSize={visualizationBoard.labelFontSize}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "CombinationChart":
        return (
          <CombinationChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      case "MapChart":
        return (
          <MapChart
            tableId={visualizationBoard.tableId}
            config={visualizationBoard.visualizationConfig}
            data={visualizationBoard.data}
            rawData={visualizationBoard.data}
            fontZoomRatio={visualizationBoard.fontZoomRatio}
          />
        );
      default:
        return null;
    }
};

const notificationPanelTemplate = () => (
  <div
    className={`notification_Panel ${visualizationBoard.boardError ? "show" : ""}`}
    acl-auto-height
    auto-height-match-element=".storyboard-board__visualization-board"
    auto-height-subtract-elements="['.visualization-title']"
  >
    <BoardNotification message={visualizationBoard.boardError} type={visualizationBoard.boardErrorType} />
  </div>
);

function _logging() {
  const { chartType, data } = visualizationBoard;
  logger.log(`${visualizationBoard.visualization.id} - chartType: ${chartType}`);
  if (chartType !== "SummaryTable") {
    logger.log(`${visualizationBoard.visualization.id} - series length: ${data.length}`);
  }
}

function setBoardError(boardModel) {
  const boardError = boardModel.boardError();
  const boardErrorLocalizeKey = boardError.localizeKey();
  visualizationBoard.boardError = Localize.getLocalizedString(boardErrorLocalizeKey);
  visualizationBoard.boardErrorType = boardError.originalError();
}

function getTreemapData(boardModel) {
  if (AppConfig.features.queryService) {
    return boardModel.chartData();
  }
  return boardModel.visualizationData();
}

function addColorsToData(data, config, chartService) {
  if (data && config) {
    const getKeyColor = chartService.getKeyColor(config.colorByField, config.colorMapping);
    return data.map(series =>
      extend({}, series, {
        color: getKeyColor(series),
      })
    );
  }
  return data;
}

function getMapChartData(boardModel) {
  if (AppConfig.features.queryService) {
    return boardModel.chartData().data();
  }
  return boardModel.visualizationData();
}

function VisualizationBoardContainer(props) {
  visualizationBoard = { ...props };
  visualizationBoard.vizLoaded = false;
  const chartDisplayConfigAdapter = new ChartDisplayConfigAdapterService(
    ColorPalette,
    props.dataModel,
    AppConfig,
    EventService
  );

  visualizationBoard.showLoadingSpinner = AppConfig.features.showLoadingSpinner;
  visualizationBoard.getVisualizationData = boardModel => {
    const visualization = boardModel.visualization();
    const chartType = visualization.vizType || visualization.type;
    const chartService = getChartServiceByType(chartType, props);
    switch (chartType) {
      case "SummaryTable":
        return boardModel.visualizationData() || boardModel.summaryTable();
      case "Treemap":
        return getTreemapData(boardModel);
      case "MapChart":
        return getMapChartData(boardModel);
      default:
        return addColorsToData(
          boardModel.chartData().representation(),
          visualizationBoard.visualizationConfig,
          chartService
        );
    }
  };

  visualizationBoard.getVisualizationConfig = boardModel => {
    if (boardModel.boardError()) return {};

    const interpretation = boardModel.interpretation();
    const visualization = boardModel.visualization();
    const visualizationConfig = boardModel.visualizationConfig();
    const chartType = visualization.vizType || visualization.type;
    const chartService = getChartServiceByType(chartType, props);
    let { dataConfig } = visualizationConfig;

    if (chartType === "CombinationChart") {
      dataConfig = { ...visualizationConfig.dataConfig, chartXAxis: visualizationConfig.dataConfig.chartRows[0] };
      delete dataConfig.chartRows;
    }

    const chartConfig =
      chartType === "SummaryTable"
        ? { dataConfig, displayConfig: { ...{ showTotals: true }, ...visualizationConfig.displayConfig } }
        : chartService.getChartDirectiveConfig(interpretation.id, {
            dataConfig,
            displayConfig: chartDisplayConfigAdapter.configToViewModel(chartType, visualizationConfig.displayConfig),
            vizId: visualization.id,
          });
    const interpretationConfig = interpretation.visualizationConfig;
    return {
      ...chartConfig,
      ...{ showControls: false },
      colorMapping: interpretationConfig.colorMapping,
    };
  };

  useMemo(() => {
    const changesObj = props;
    if (changesObj.boardModel) {
      const { boardModel } = changesObj;
      if (boardModel.isValid()) {
        logger.log(`${boardModel.visualizationId()} visualizationBoard.$onChanges - Start`);
        visualizationBoard.showBoard = boardModel.showBoard();
        visualizationBoard.boardError = "";
        visualizationBoard.boardErrorType = "";
        visualizationBoard.boardType = boardModel.type();
        visualizationBoard.boardId = boardModel.id();
        visualizationBoard.tableId = boardModel.controlTestId();
        visualizationBoard.interpretation = boardModel.interpretation();
        visualizationBoard.visualization = boardModel.visualization();
        visualizationBoard.chartType =
          visualizationBoard.visualization.vizType || visualizationBoard.visualization.type;
        visualizationBoard.visualizationConfig = visualizationBoard.getVisualizationConfig(boardModel);
        visualizationBoard.data = visualizationBoard.getVisualizationData(boardModel);
        logger.log(`${boardModel.visualizationId()} visualizationBoard.$onChanges - End`);
        _logging();

        visualizationBoard.vizLoaded = true;
      } else if (boardModel.boardError()) {
        visualizationBoard.showBoard = true;
        setBoardError(boardModel);
      } else {
        visualizationBoard.vizLoaded = false;
      }
    }

    if (changesObj.presentationMode) {
      visualizationBoard.labelFontSize = changesObj.presentationMode ? "large" : undefined;
    }
  }, [props]);

  return (
    <div
      className="storyboard-board__visualization-board"
      onMouseEnter={() => {
        if (props.onMouseEnterDrilldown) {
          props.onMouseEnterDrilldown();
        }
      }}
      onMouseLeave={() => {
        if (props.onMouseLeaveDrilldown) {
          props.onMouseLeaveDrilldown();
        }
      }}
    >
      {!visualizationBoard.boardType || visualizationBoard.boardType === "visualization" ? (
        <div className="visualization-title">
          {visualizationBoard.visualization ? visualizationBoard.visualization.title : ""}
        </div>
      ) : null}
      {visualizationBoard.showLoadingSpinner &&
      !visualizationBoard.showBoard &&
      !visualizationBoard.boardError &&
      !visualizationBoard.boardModel ? (
        <div className="storyboard-board__loading">
          <acl-tile-loading />
        </div>
      ) : null}
      {visualizationTemplate(visualizationBoard.chartType, props.dataModel)}
      {notificationPanelTemplate()}
    </div>
  );
}
/* eslint-disable */
VisualizationBoardContainer.propTypes = {
  dataModel: PropTypes.any,
  editMode: PropTypes.bool,
  fontZoomRatio: PropTypes.string,
  onMouseEnterDrilldown: PropTypes.func,
  onMouseLeaveDrilldown: PropTypes.func,
  onDrilldownClick: PropTypes.func,
  presentationMode: PropTypes.bool,
};
/* eslint-enable */

const getChartServiceByType = (chartType, props) => {
  switch (chartType) {
    case "LineChart":
      return new LineChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "BarChart":
      return new BarChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "MapChart":
      return new MapChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "PieChart":
      return new PieChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "BubbleChart":
      return new BubbleChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "StackedAreaChart":
      return new AreaChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "CombinationChart":
      return new CombinationChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "Treemap":
      return new TreemapChartService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "Heatmap":
      return new HeatMapService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "StatisticsViz":
      return new StatisticsService(ColorPalette, props.dataModel, AppConfig, EventService);
    case "SummaryTable":
      return new SummaryTableService(ColorPalette, props.dataModel, AppConfig, EventService);
    default:
      return new ChartService(ColorPalette, props.dataModel, AppConfig, EventService);
  }
};

//window.visualizationBoardContainer = VisualizationBoardContainer;

export default VisualizationBoardContainer;
