import { MetricHeader } from "@acl-services/sriracha-metrics/dist/Metrics";
import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";
import BoardOrganizer from "../boardOrganizer/boardOrganizer.service";
import services from "../../services/glue/appGlue";
import User from "../services/user.service";
import { ERROR_CODES } from "../boardDataLoader/errorCode.service";

window.MetricHeader = MetricHeader;

var aclStoryboardBoard = {
  restrict: "E",
  bindings: {
    board: "<",
    editMode: "<",
    fontZoomRatio: "<",
    globalFilters: "<",
    storyboardModel: "<",
    boardModel: "<",
    onRemoveBoard: "&",
    onLoadMoreData: "&",
    presentationMode: "<",
    onDrilldownNoDataConfirmation: "&",
  },
  controllerAs: "storyboardBoard",
  template: getTemplate,
  controller: StoryboardBoardController,
};

function StoryboardBoardController(
  $document,
  $element,
  $scope,
  $timeout,
  AppConfig,
  ColorPalette,
  DataFilter,
  Localize,
  UnsavedData,
  AccessChecker
) {
  "ngInject";
  var storyboardBoard = this;

  var redrawTimeoutId = null;

  storyboardBoard.showLoadingSpinner = AppConfig.features.showLoadingSpinner;
  storyboardBoard.isDrilldown = false;
  storyboardBoard.accessChecker = AccessChecker;
  let ACCESS_CHECKER_ENTITIES_MAP = {
    visualization: "interpretation",
    table: "interpretation",
    metric: "metric",
    trigger: "metric",
  };

  storyboardBoard.$onInit = function() {
    storyboardBoard.hasNoData = false;
    if (angular.isUndefined(storyboardBoard.board.type)) storyboardBoard.board.type = "visualization";
    switch (storyboardBoard.board.type) {
      case "text":
        processTextBoard();
        break;
      case "table":
        initTableBoard();
        break;
    }
    setTextBoardEditMode(storyboardBoard.editMode);
    checkDrilldownButtonAccess();
  };

  storyboardBoard.$onChanges = function(changesObj) {
    if (changesObj.editMode) {
      setTextBoardEditMode(changesObj.editMode.currentValue);
    }
    if (changesObj.globalFilters) {
      resetScope();
    }
    if (changesObj.boardModel && changesObj.boardModel.currentValue !== undefined) {
      const boardModel = changesObj.boardModel.currentValue;
      storyboardBoard.showBoard = boardModel.showBoard();
      switch (boardModel.type()) {
        case "metric":
        case "trigger":
          setupMetricBoard(boardModel);
          break;
        case "visualization":
          setupVisualizationBoard(boardModel);
          break;
        case "table":
          setupTableBoard(boardModel);
          break;
      }
    }
  };

  $scope.$watch(
    function() {
      return $element.find(".storyboard-content").width();
    },
    function() {
      delayedRedraw();
    }
  );

  $scope.$watch(
    function() {
      return $element.find(".storyboard-content").height();
    },
    function() {
      delayedRedraw();
    }
  );

  storyboardBoard.autoHeightRefresh = () => $($element).height();

  storyboardBoard.onMouseEnterDelete = event => {
    $(event.target)
      .closest(".storyboard-board")
      .addClass("is-active-delete");
  };

  storyboardBoard.onMouseLeaveDelete = event => {
    $(event.target)
      .closest(".storyboard-board")
      .removeClass("is-active-delete");
  };

  storyboardBoard.onMouseEnterDrilldown = () => {
    $($element)
      .closest(".storyboard-board")
      .addClass("is-active-drilldown");
  };

  storyboardBoard.onMouseLeaveDrilldown = () => {
    $($element)
      .closest(".storyboard-board")
      .removeClass("is-active-drilldown");
  };

  storyboardBoard.removeBoard = function() {
    storyboardBoard.onRemoveBoard({ board: storyboardBoard.board });
  };

  storyboardBoard.isMetricOrTrigger = function() {
    return storyboardBoard.board.type === "metric" || storyboardBoard.board.type === "trigger";
  };

  function checkDrilldownButtonAccess() {
    const boardModel = storyboardBoard.board;
    const isAnonymous = User.isAnonymous();
    const currentUser = User.getCurrentUser();
    if (isAnonymous && currentUser) {
      retrieveDrilldown(boardModel);
    } else if (storyboardBoard.isMetricOrTrigger()) {
      retrieveDrilldown(boardModel);
    }
  }
  async function retrieveDrilldown(boardModel) {
    storyboardBoard.isDrilldown = await getDrilldownComponentDetail(boardModel);
  }
  function getDrilldownComponentDetail(boardModel) {
    if (boardModel) {
      const objAccessChecker = storyboardBoard.accessChecker;
      const identifier = getAccessCheckerIdentifier(boardModel);
      const entityType = ACCESS_CHECKER_ENTITIES_MAP[boardModel.type];
      return objAccessChecker.can(objAccessChecker.actions.drilldown, entityType, identifier);
    }
  }
  function getAccessCheckerIdentifier(boardModel) {
    if (boardModel.type === "visualization" || boardModel.type === "table") {
      return boardModel.interpretation_id;
    }
    return boardModel.metricId;
  }

  storyboardBoard.handleDrilldownClick = () => {
    if (AppConfig.features.carryStoryboardFilters && storyboardBoard.hasNoData) {
      storyboardBoard.onDrilldownNoDataConfirmation({
        confirm: function() {
          handleDrilldown(false);
        },
      });
    } else {
      handleDrilldown(true);
    }
  };

  let handleDrilldown = carryFilters => {
    const { board } = storyboardBoard;
    storyboardBoard.storyboardModel.handleDrilldown(board, carryFilters);
  };

  function setupVisualizationBoard(visualizationBoard) {
    storyboardBoard.vizBoard = {
      boardModel: visualizationBoard,
    };
    if (
      visualizationBoard.boardError() &&
      visualizationBoard.boardError().originalError() === ERROR_CODES.VISUALIZATION_NO_DATA
    ) {
      storyboardBoard.hasNoData = true;
    } else {
      storyboardBoard.hasNoData = false;
    }
  }

  function setupTableBoard(tableBoard) {
    if (tableBoard.isValid()) {
      setupTableBoardData(tableBoard);
      storyboardBoard.tableBoardLoaded = true;
    } else if (tableBoard.boardError()) {
      setBoardError(tableBoard);
    } else {
      storyboardBoard.tableBoardLoaded = false;
    }
  }

  function initTableBoard() {
    storyboardBoard.tableBoard = angular.extend({}, storyboardBoard.tableBoard, {
      autoHeight: {
        matchElement: "#storyboard-board-" + storyboardBoard.board.id,
        subtractElements: [".table-board__table-name", 30],
      },
      getAttachmentUrl: AppConfig.attachmentUrl(storyboardBoard.board.controlTestId),
      onScrollToBottom: function() {
        storyboardBoard.onLoadMoreData({ board: storyboardBoard.board });
      },
      onHeightAdjust: function() {
        // when height change, fire a resize event so HoT know it needs to redraw the table.
        $timeout(function() {
          var resizeEvent = $document[0].createEvent("HTMLEvents");
          resizeEvent.initEvent("resize", true, false);
          window.dispatchEvent(resizeEvent);
        });
      },
    });

    $scope.$watch(
      () => $element.find(".storyboard-content").height(),
      newValue => {
        if (newValue !== undefined) {
          storyboardBoard.tableBoard.onHeightAdjust();
        }
      }
    );
  }

  function setupTableBoardData(tableBoard) {
    const interpretation = tableBoard.interpretation();
    const recordset = tableBoard.recordset();
    const table = tableBoard.table();
    if (storyboardBoard.tableBoard === undefined) storyboardBoard.tableBoard = {};
    storyboardBoard.tableBoard.name = interpretation.visualizationInfo.title;
    storyboardBoard.tableBoard.interpretationId = interpretation.id;

    storyboardBoard.tableBoard.fields = table.fields();
    storyboardBoard.tableBoard.filterFieldNames = DataFilter.getFilterFieldNames(interpretation.filterConfig);
    storyboardBoard.tableBoard.sortFieldName = recordset.filterConfig().sortField();
    storyboardBoard.tableBoard.sortOrder = recordset.filterConfig().sortOrder();

    storyboardBoard.tableBoard.tableConfigFieldObjs = interpretation.tableConfig.columns;
    setTableConditionalFormatting(interpretation);

    storyboardBoard.tableBoard.data = recordset.records();
    if (recordset.recordCount() === 0) {
      storyboardBoard.hasNoData = true;
    } else {
      storyboardBoard.hasNoData = false;
    }
  }

  function setupMetricBoard(metricBoard) {
    storyboardBoard.metricBoard = {};

    if (metricBoard && metricBoard.isValid()) {
      onMetricDefined(metricBoard);
      onMetricTableDefined(metricBoard);
      onMetricSparklineDataDefined(metricBoard);
      storyboardBoard.metricLoaded = true;
    } else if (metricBoard && metricBoard.boardError()) {
      setBoardError(metricBoard);
    } else {
      storyboardBoard.metricLoaded = false;
    }
  }

  function resetScope() {
    if (storyboardBoard.tableBoard) {
      storyboardBoard.tableBoard.data = [];
    }
  }

  function processTextBoard() {
    const colorPal = new ColorPalette();
    const palette = colorPal
      .darkColors()
      .concat(colorPal.baseColors())
      .concat(colorPal.lightColors())
      .concat(colorPal.lighterColors())
      .concat(colorPal.greyColors());
    storyboardBoard.textBoardProps = {
      boardId: storyboardBoard.board.id,
      title: storyboardBoard.board.title,
      text: storyboardBoard.board.text,
      onChange: saveText,
      editMode: storyboardBoard.editMode,
      palette: palette,
    };
  }

  function setTextBoardEditMode(editMode) {
    if (storyboardBoard.board.type === "text") storyboardBoard.textBoardProps.editMode = editMode;
  }

  function saveText(textBoardData) {
    BoardOrganizer.updateTextBoard(storyboardBoard.board.id, textBoardData.title, textBoardData.text);
    UnsavedData.set(true);
  }

  function setTableConditionalFormatting(interpretation) {
    var fieldsMap = {};
    storyboardBoard.tableBoard.fields.forEach(function(field) {
      fieldsMap[field.name()] = {
        type: field.type(),
      };
    });

    ConditionalFormatting.updateFormattingConditions(
      fieldsMap,
      interpretation.tableConfig.formatting,
      interpretation.id
    );
  }

  function onMetricDefined(metricBoard) {
    const metric = metricBoard.metric();

    storyboardBoard.metricBoard.metric = metric;
    storyboardBoard.metricBoard.conditionGroupId = metricBoard.conditionGroupId();
    storyboardBoard.metricBoard.name = metricBoard.metricName();
    storyboardBoard.metricBoard.type = metricBoard.metricType();
    storyboardBoard.metricBoard.showSparkline = metricBoard.showSparkline();
  }

  function onMetricTableDefined(metricBoard) {
    const table = metricBoard.table();
    storyboardBoard.metricBoard.field = getFieldByName(metricBoard.fieldName(), table);
    storyboardBoard.metricBoard.timeField = getFieldByName(metricBoard.timeFieldName(), table);
  }

  function onMetricSparklineDataDefined(metricBoard) {
    const metricSparkline = metricBoard.metricSparkline();
    storyboardBoard.metricBoard.sparklineData = metricSparkline;
    if (metricSparkline && metricSparkline.values() && metricSparkline.values().length === 0) {
      storyboardBoard.hasNoData = true;
    } else {
      storyboardBoard.hasNoData = false;
    }
  }

  function setBoardError(boardModel) {
    const boardError = boardModel.boardError();
    const boardErrorLocalizeKey = boardError.localizeKey();
    storyboardBoard.error = {
      message: Localize.getLocalizedString(boardErrorLocalizeKey),
      type: storyboardBoard.boardModel._data.boardError.originalError(),
    };
  }

  function getFieldByName(fieldName, table) {
    if (storyboardBoard) {
      const tableModel = table || storyboardBoard.storyboardModel.getTable(storyboardBoard.board.controlTestId);
      return tableModel ? tableModel.fieldByName(fieldName) : undefined;
    }
  }

  function delayedRedraw() {
    var redrawDelayMillis = 300;
    if (redrawTimeoutId === null) {
      redrawTimeoutId = $timeout(function() {
        if (["visualization", "metric", "trigger"].includes(storyboardBoard.board.type)) {
          $scope.$broadcast("chartRedraw");
        }
        redrawTimeoutId = null;
      }, redrawDelayMillis);
    }
  }
}

function getTemplate() {
  return `
    <div id="{{'storyboard-board-' + storyboardBoard.board.id}}"  class="storyboard-content board-type__{{ storyboardBoard.board.type}}">
      <div class="storyboard-content__table-template" ng-if="storyboardBoard.board.type==='table' && !storyboardBoard.error">
        ${getTableTemplate()}
      </div>
       ${
         services.AppConfig.features.enableStoryboardReactCharts
           ? getStoryBoardsReactCharts()
           : getStoryBoardsAngularCharts()
       }
      <react-component ng-if="storyboardBoard.board.type=='text'" name="TextBoard" props="storyboardBoard.textBoardProps"></react-component>
      <div class="storyboard-content__metric-template" ng-if="storyboardBoard.isMetricOrTrigger() && !storyboardBoard.error">
        ${getMetricTemplate()}
      </div>
      ${services.AppConfig.features.drillDownButton ? getStoryboardDrillDown() : getDrilldownTemplate()}
      ${getDeleteBoardTemplate()}
      <acl-board-notification
        ng-if="storyboardBoard.error"
        message="storyboardBoard.error.message"
        type="storyboardBoard.error.type"></acl-board-notification>
    <div>`;
}

function getTableTemplate() {
  return `
    <div class="storyboard-board__loading" ng-if="storyboardBoard.showLoadingSpinner && !storyboardBoard.showBoard && !error && !storyboardBoard.tableBoardLoaded">
      <acl-tile-loading></acl-tile-loading>
    </div>
    <div class="storyboard-board__table-board" ng-if="storyboardBoard.showBoard && !error && storyboardBoard.tableBoardLoaded">
      <div class="table-board__table-name">
        {{storyboardBoard.tableBoard.name}}
      </div>
      <div class="table-board__data-grid"
        acl-auto-height
        auto-height-match-element="{{storyboardBoard.tableBoard.autoHeight.matchElement}}"
        auto-height-subtract-elements="storyboardBoard.tableBoard.autoHeight.subtractElements">
        <acl-data-grid
          data="storyboardBoard.tableBoard.data"
          field-format-identifier="storyboardBoard.tableBoard.interpretationId"
          fields="storyboardBoard.tableBoard.fields"
          filter-field-names="storyboardBoard.tableBoard.filterFieldNames"
          font-zoom-ratio="storyboardBoard.fontZoomRatio"
          is-header-clicking-enabled="false"
          is-presentation-mode="storyboardBoard.presentationMode"
          is-shrink-wrap-enabled="true"
          is-row-highlighting-enabled="true"
          is-zebra-striping-enabled="true"
          on-row-click="storyboardBoard.tableBoard.onRowClick(row, rowId)"
          on-scroll-to-bottom="storyboardBoard.tableBoard.onScrollToBottom()"
          outside-click-deselects="storyboardBoard.presentationMode"
          sort-field-name="storyboardBoard.tableBoard.sortFieldName"
          sort-order="storyboardBoard.tableBoard.sortOrder"
          table-config-field-objs="storyboardBoard.tableBoard.tableConfigFieldObjs"
          get-attachment-url="storyboardBoard.tableBoard.getAttachmentUrl"
        >
        </acl-data-grid>
      </div>
    </div>
  `;
}

function getMetricTemplate() {
  return `
    <div class="storyboard-board__loading" ng-if="storyboardBoard.showLoadingSpinner && !storyboardBoard.showBoard && !error && !storyboardBoard.metricLoaded">
      <acl-tile-loading></acl-tile-loading>
    </div>
    <div class="sriracha storyboard-board__metric-board" ng-if="storyboardBoard.showBoard && !error && storyboardBoard.metricLoaded">
      <react-component
        name="MetricHeader"
        props="{
          metric: storyboardBoard.metricBoard.metric.toJson(),
          thresholdId: storyboardBoard.metricBoard.conditionGroupId
        }">
      </react-component>
      <div class="metric-board__metric-indicator">
        <acl-indicator
          class="metric-board__metric-indicator-component"
          show-only-threshold="storyboardBoard.metricBoard.conditionGroupId"
          metric-model="storyboardBoard.metricBoard.metric"
          sparkline-model="storyboardBoard.metricBoard.sparklineData"
          show-insufficient-sparkline-data-message="storyboardBoard.editMode"
          show-sparkline="storyboardBoard.metricBoard.showSparkline"
          presentation-mode="storyboardBoard.presentationMode">
          font-zoom-ratio="storyboardBoard.fontZoomRatio"
        </acl-indicator>
      </div>
    </div>
  `;
}

function getDrilldownTemplate() {
  return `<acl-drilldown-button
    board-model="storyboardBoard.boardModel"
    edit-mode="storyboardBoard.editMode"
    on-drilldown-click="storyboardBoard.handleDrilldownClick()",
    on-mouse-enter-drilldown="storyboardBoard.onMouseEnterDrilldown()",
    on-mouse-leave-drilldown="storyboardBoard.onMouseLeaveDrilldown()",
  ></acl-drilldown-button>`;
}
function getStoryboardDrillDown() {
  return `
    <story-board-drilldown-button
      re-in-board-model="storyboardBoard.boardModel"
      re-in-edit-mode="storyboardBoard.editMode"
      re-cb-on-drilldown-click="storyboardBoard.handleDrilldownClick()"
      re-cb-on-mouse-enter-drilldown="storyboardBoard.onMouseEnterDrilldown()",
      re-cb-on-mouse-leave-drilldown="storyboardBoard.onMouseLeaveDrilldown()",
      re-in-presentation-mode="storyboardBoard.presentationMode",
      re-in-is-drilldown="storyboardBoard.isDrilldown",
      ></story-board-drilldown-button>
  `;
}
function getDeleteBoardTemplate() {
  return `<a
    class="storyboard-icon storyboard-remove-board"
    ng-if="storyboardBoard.editMode"
    ng-click="storyboardBoard.removeBoard()"
    ng-mouseenter="storyboardBoard.onMouseEnterDelete($event)"
    ng-mouseleave="storyboardBoard.onMouseLeaveDelete($event)">
    <i class="acl-i-trashbin"></i>
  </a>`;
}
// Responsible For Rendering React Charts
function getStoryBoardsReactCharts() {
  return `
    <visualization-board-container
        name="visualizationBoardContainer"
        re-in-board-model="storyboardBoard.boardModel"
        re-in-edit-mode="storyboardBoard.editMode"
        re-in-font-zoom-ratio="storyboardBoard.fontZoomRatio"
        re-in-presentation-mode="storyboardBoard.presentationMode"
        re-in-data-model="storyboardBoard.dataModel"
        re-cb-on-mouse-enter-drilldown="storyboardBoard.onMouseEnterDrilldown()"
        re-cb-on-mouse-leave-drilldown="storyboardBoard.onMouseLeaveDrilldown()"
        ng-if="!storyboardBoard.board.type || storyboardBoard.board.type=='visualization'"
        >
      </visualization-board-container>
  `;
}
// Responsible For Rendering Angular Charts
function getStoryBoardsAngularCharts() {
  return `
    <acl-visualization-board
        ng-if="!storyboardBoard.board.type || storyboardBoard.board.type=='visualization'"
        board-model="storyboardBoard.vizBoard.boardModel"
        edit-mode="storyboardBoard.editMode"
        font-zoom-ratio="storyboardBoard.fontZoomRatio"
        presentation-mode="storyboardBoard.presentationMode">
      </acl-visualization-board>
  `;
}

angular.module("acl.storyboard.storyboard").component("aclStoryboardBoard", aclStoryboardBoard);
