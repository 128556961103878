import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import ConditionalOperators from "@viz-ui/services/quickMenu/conditionalFormat/conditionalOperators";
import ErrorReporter from "@viz-ui/services/common/errorReporterService";

angular.module("acl.visualizer.dataTable").service("DataTableService", (DataModel, EventService, TableAdapter) => {
  "ngInject";

  const exceptionHandler = ErrorReporter.instance("Visualizer");
  const eventTimeout = 60000; //TODO: determine if a timeout makes sense.
  const eventService = EventService.register("acl.visualizer.dataTable");

  const service = {
    loadRawTableMetadata: tableIdentifier =>
      eventService.request("dataTable.getTableMetadata", eventTimeout, tableIdentifier),

    loadTableMetadata: tableIdentifier => {
      UsageTracker.mark("dataTable.loadTableMetadata");
      return service.loadRawTableMetadata(tableIdentifier).then(
        rawMetadata => {
          UsageTracker.mark("dataTable.tableMetadataLoaded");
          return formatRawMetadata(rawMetadata);
        },
        response => Promise.reject(response ? response.error : response)
      );
    },

    loadTableData: (includeMetadata = true) => {
      UsageTracker.mark("dataTable.loadTableData");
      return eventService
        .request(
          "dataTable.getTableData",
          eventTimeout,
          DataModel.table.id(),
          DataModel.getFilterConfig(),
          includeMetadata
        )
        .then(
          rawData => {
            UsageTracker.mark("dataTable.tableDataLoaded");
            //should not depend on an httpPromise being used in backend
            return formatRawData(rawData, includeMetadata);
          },
          response => {
            const error = response.data && response.data.error;
            return Promise.reject(error);
          }
        );
    },

    loadCommentCounts: () => {
      return eventService
        .request("dataTable.getCommentCounts", eventTimeout, DataModel.table.id())
        .then(({ result }) => result)
        .catch(e => {
          exceptionHandler(e);
          return { data: [] };
        });
    },

    cellTemplate: field => {
      const fieldType = field.type ? " " + field.type : "";
      return (
        '<div class="ngCellText' +
        fieldType +
        '" ng-class="col.colIndex()" ' +
        "ng-style=\"{'color': col.colDef.textColor(COL_FIELD), 'background-color': col.colDef.backgroundColor(COL_FIELD)}\">" +
        '<i ng-show="!!col.colDef.iconClass(COL_FIELD)" ng-class="col.colDef.iconClass(COL_FIELD)" ' +
        "ng-style=\"{'color': col.colDef.iconColor(COL_FIELD)}\"></i>" +
        (field.isHtml
          ? '<span ng-cell-text ng-bind-html="COL_FIELD"></span>'
          : "<span ng-cell-text>{{COL_FIELD}}</span>") +
        "</div>"
      );
    },

    getFilteredRecordCount: (tableIdentifier, filterConfig) => {
      return eventService.request("dataTable.getFilteredRecordCount", eventTimeout, tableIdentifier, filterConfig).then(
        response => response,
        response => Promise.reject(response.error)
      );
    },

    deleteField: field => {
      const fieldName = field.fieldName;
      return eventService.request("dataTable.deleteField", eventTimeout, fieldName);
    },
  };

  function formatRawMetadata(rawMetadata) {
    const {
      capabilities: {
        delete_columns: canDeleteColumns,
        manage_triggers: canManageTriggers,
        remediation: canRemediateRecords,
      },
    } = rawMetadata;

    const metadata = {
      displayName: rawMetadata.name,
      recordCount: rawMetadata.recordCount,
      certificationsTable: rawMetadata.certificationsTable,
      hasStaticField: rawMetadata.hasStaticField,
      fields: _(rawMetadata.fields)
        .map((field, i) => ({
          colId: "col" + (i + 1),
          fieldName: field.name,
          displayName: field.columnTitle || field.name,
          isSortable: field.isSortable !== false,
          isQuickFilterable: field.isQuickFilterable !== false,
          criteriaFilter: {
            newFilter: {},
            filters: [],
          },
          isPrimaryKey: field.isPrimaryKey,
          removable: field.removable,
        }))
        .reduce((fieldsObj, field) => {
          fieldsObj[field.fieldName] = field;
          return fieldsObj;
        }, {}),
      capabilities: {
        canDeleteColumns,
        canManageTriggers,
        canRemediateRecords,
      },
    };

    DataModel.table.model(TableAdapter.deserializeTable(rawMetadata.id, rawMetadata));

    updateFields(metadata);

    return metadata;
  }

  function updateFields(metadata) {
    const oldFields = DataModel.table.fields();
    const newFields = metadata.fields;
    for (let oldFieldName in oldFields) {
      const oldField = oldFields[oldFieldName];
      const newField = newFields[oldFieldName];
      if (
        oldField.criteriaFilter.operators &&
        oldField.criteriaFilter.operators.length &&
        newField &&
        newField.criteriaFilter
      ) {
        newField.criteriaFilter.operators = angular.copy(oldField.criteriaFilter.operators);
      }
    }
    DataModel.table.fields(newFields);
  }

  function formatRawData(rawData, includeMetadata) {
    const metadata = includeMetadata ? formatRawMetadata(rawData.metaData) : {};
    const fields = DataModel.table.fields();

    if (rawData.jobId) {
      DataModel.filterConfig.jobId(rawData.jobId);
    }
    for (let fieldName in fields) {
      const field = DataModel.table.field(fieldName);
      field.isHtml = DataModel.tableConfig.isHtml(fieldName) || false;
      field.isURL = DataModel.tableConfig.isURL(fieldName) || false;

      if (field.type !== rawData.result.columns[fieldName]) {
        field.type = field.type || rawData.result.columns[fieldName];
        const currentOperators = field.criteriaFilter.operators;
        if (!currentOperators || (currentOperators && currentOperators.length === 0)) {
          field.criteriaFilter.operators = ConditionalOperators.getOperatorsByType(field.type);
        }
        fields[fieldName] = field;
      }
    }
    DataModel.table.fields(fields);

    const formattedResponse = {
      result: rawData.result.data,
      filteredRecordCount: rawData.result.totalRecordCount,
      lastRecordNumber: rawData.result.lastRecNo,
    };
    if (includeMetadata) {
      return { ...formattedResponse, ...{ metaData: metadata } };
    } else {
      return formattedResponse;
    }
  }

  return service;
});
