import React from "react";
import PropTypes from "prop-types";
import "./boardNotification.scss";

const BoardNotification = ({ message, type }) => {
  // Determine the icon and class based on the type
  const iconClass =
    type === "404" || type === "INTERPRETATION_DELETED" ? "acl-i-exclamation-circle" : "acl-i-info-circle";

  const notificationClass = type === "404" || type === "INTERPRETATION_DELETED" ? "deletedBoard" : "";

  return (
    <div className={`acl-row storyboard-board__notification ${notificationClass}`}>
      <div className="acl-notification-msg">
        <i className={`acl-notification-icon ${iconClass}`} />
        <p className="acl-notification-msg__body">{message}</p>
      </div>
    </div>
  );
};

// Define PropTypes for type checking
BoardNotification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default BoardNotification;
