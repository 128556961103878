require("script-loader!../node_modules/ngreact/ngReact.min");
require("./lib/jquery.fanButtons");
require("./lib/greensock/TweenMax.min");
window.Redux = require("redux");

window.ACLUISetup = require("acl-ui/more/js/SidePanel").default;

require("./app.js");
require("./modules/components/common/acl_notification.es6");
require("./modules/components/common/rich_text_editor.es6");
require("./modules/components/common/select_options.es6");
require("./modules/components/dropdown.es6");
require("./modules/components/vizDropdown.es6");
require("./modules/components/metricDropdown.es6");
require("./modules/components/boardSelector.es6");
require("./modules/components/deleteConfirmModal.es6");
require("./modules/components/storyboard_action_panel.es6");
require("./modules/components/storyboard_mode_toggle_bar.es6");
require("./modules/components/text_board.es6");
require("./modules/board/storyboardBoard.module");
require("./modules/board/boardNotification.component");
require("./modules/board/summaryTableBoard.component");
require("./modules/board/visualizationBoard.component");
require("@viz-ui/components/StoryBoardsChart/VisualizationBoardContainer.jsx");

require("./modules/storyboardSharing/actions/assignmentActions");
require("./modules/storyboardSharing/actions/linkActions");
require("./modules/storyboardSharing/reducers/assignmentReducers");
require("./modules/storyboardSharing/reducers/linkReducers");
require("./modules/storyboardSharing/services/storyboardAssignmentService");
require("./modules/storyboardSharing/services/storyboardLinkService");
require("./modules/storyboardSharing/containers/storyboardShareContainer.es6");
require("./modules/storyboardSharing/components/storyboardShareHeader.es6");
require("./modules/storyboardSharing/components/storyboardShareNav.es6");
require("./modules/storyboardSharing/components/exportStoryboardToPDF.es6");
require("./modules/storyboardSharing/components/usersList.es6");
require("./modules/storyboardSharing/components/usersListRow.es6");
require("./modules/storyboardSharing/components/userPane.es6");
require("./modules/storyboardSharing/components/linkPane.es6");
require("./modules/storyboardSharing/components/aclPopover.es6");
require("./modules/storyboardSharing/components/publicLink.es6");
require("./modules/storyboardSharing/components/linkActionsSection.es6");
require("./modules/storyboardSharing/components/storyboardShareUserSelector.es6");
require("./modules/storyboardSharing/components/userSelectorRow.es6");
require("./modules/storyboardSharing/components/userSelector.es6");
require("./modules/storyboardSharing/components/roleSelect.es6");

require("./modules/leftNavigation/LeftNavigation.es6");

require("./modules/appConfig/appConfig.module");
require("./modules/interpretation/interpretation.module");
require("./modules/storyboard/storyboard.module");
require("./modules/visualization/visualization.module");

require("./modules/interpretation/interpretation.backend");
require("./modules/interpretation/interpretationValidator.service");
require("./modules/storyboard/storyboard.service");
require("./modules/storyboard/storyboard.component");
require("./modules/storyboard/storyboard.board.component");
require("./modules/storyboardDataStore/storyboardDataStore.module");
require("./modules/boardDataLoader/boardDataLoader.module");
require("./modules/storyboard/boardRowResizable.directive");
require("./modules/storyboards/storyboards.controller");
require("./modules/unsaveModal/saveModal");
require("./modules/visualization/visualization.backend");
require("./modules/visualization/visualization.service");
require("./modules/visualization/visualizationValidator.service");
require("./modules/services/unsavedData.service");
require("./modules/services/componentLoader.service");

require("./modules/presenter/presenter.module");
require("./modules/presenter/keyboardShortcut.module");

require("../results_container/services/chartsBackend/chartsBackend.module");
require("../results_container/services/metricBackend/metricBackendModule");
require("../results_container/services/metricBackend/metricBackendService");
require("../results_container/services/dataTableBackend/dataTableBackendModule");
require("../results_container/services/modulesBackend/modulesBackend");

require("./services/accessControlBackend/accessControlBackend.module");
require("./services/accessControlBackend/accessControlBackend.service");

require("./modules/globalFilters/components/FilterPanel");
